<!-- 首页商品列表 -->
<template>
  <div class="homeProductList" v-if="productGroup">
    <div class="title">
      {{productGroup.title?productGroup.title:productGroup.name}}
      <div class="more" @click.stop="toProListPage(productGroup.id)">
        <span>{{$t('home.more')}}</span>
        <img src="../assets/icon/arrow-right.png" />
      </div>
    </div>
    <div class="product-list">
      <div
        :class="{'btn-prv':true,
        'swiper-btn':true,
        'opacity': productGroup.category_goods_arr.length < 6 || swiperIndex == 0}"
        @click.stop="changeSwiperIndex('prv')"
      >
        <img src="../assets/icon/jiantou_zuo.png" />
      </div>

      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide
          v-for="(product,productIndex) in productGroup.category_goods_arr"
          :key="productIndex"
        >
          <template v-if="product">
            <v-home-product-item :product="product" @chooseSpecShow="handleChooseSpecShow"></v-home-product-item>
          </template>
        </swiper-slide>
      </swiper>
      <div
        class="btn-next swiper-btn"
        :class="{'opacity-1': productGroup.category_goods_arr.length <6,
                         'opacity': isEnd && productGroup.category_goods_arr.length >6}"
        @click.stop="changeSwiperIndex('next')"
      >
        <img src="../assets/icon/jiantou_you.png" />
      </div>
    </div>
    <v-choose-spec
      v-if="showChooseSpec"
      @fnCancelChoose="showChooseSpec=false"
      :product="product"
      :showChooseSpecType="showChooseSpecType"
    ></v-choose-spec>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import homeProductItem from '../components/homeProductItem.vue'
import chooseSpec from '../components/chooseSpec.vue'

export default {
  name: 'homeProductList',
  data() {
    return {
      showChooseSpecType: null,
      showChooseSpec: false,
      product: null,
      userinfo: this.common.getUserInfo(),
      swiperOption: {
        initialSlide: 0,
        loop: false,
        centeredSlides: false,
        centeredSlidesBounds: true,
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 11,
        touchRatio: 1,
        on: {
          slideChangeTransitionEnd: this.slideChangeTransitionEnd,
        },
      },
      swiperIndex: 0,
      isEnd: false,
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },
  props: {
    productGroup: {
      type: Object,
    },
  },
  methods: {
    slideChangeTransitionEnd: function () {
      this.swiperIndex = this.swiper.realIndex
      this.isEnd = this.swiper.isEnd
    },
    handleChooseSpecShow: function (data) {
      this.product = data.product
      this.showChooseSpecType = data.type
      this.showChooseSpec = true
    },
    changeSwiperIndex: function (type) {
      if (type == 'next') this.swiper.slideNext(250, true)
      if (type == 'prv') this.swiper.slidePrev(250, true)

      this.swiperIndex = this.swiper.realIndex
      this.isEnd = this.swiper.isEnd
    },
    /** 商品列表页面 (分类) */
    toProListPage(category_id) {
      let routeData = this.$router.resolve({
        path: '/list',
        query: {
          category_id: category_id,
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
  components: {
    swiper,
    swiperSlide,
    'v-home-product-item': homeProductItem,
    'v-choose-spec': chooseSpec,
  },
}
</script>
<style scoped>
.homeProductList {
  margin: 30px 82px;
}

.homeProductList .title {
  position: relative;
  color: #333333;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  font-family: SimHei, PingFangSC-Medium, PingFang SC, sans-serif;
}

.homeProductList .more {
  position: absolute;
  display: flex;
  height: 16px;
  bottom: 0;
  right: 60px;
  align-items: center;
  cursor: pointer;
}

.homeProductList .more span {
  color: #999;
  font-size: 16px;
}

.homeProductList .more img {
  width: 15px;
  height: 15px;
}

.homeProductList .product-list {
  background-color: white;
  min-height: 354px;
  margin-top: 15px;
}

.homeProductList .swiper-slide {
  /* border: 1px solid red; */
  /* height: 354px; */
  min-height: 354px;
  /* width: 168px; */
}

.product-list {
  display: flex;
  /* align-items: center; */
}

.product-list .swiper-container {
  flex: 1;
  margin-left: 33px;
  margin-right: 33px;
}

.swiper-btn {
  width: 30px;
  height: 65px;
  background-color: #c4e0ee;
  margin-top: 72px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.opacity {
  opacity: 0;
}

.opacity-1 {
  opacity: 0;
}

.swiper-btn img {
  width: 19px;
  height: 19px;
}

.priduct-item {
  width: 170px;
  height: 100%;
  padding: 20px 0;
  cursor: pointer;
}

.priduct-item .image-box {
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priduct-item .image-box img {
  width: 170px;
  height: 170px;
  -o-object-fit: contain;
  object-fit: contain;
}

.priduct-item .product-name {
  color: #333;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 11px 15px;
}

.priduct-item .product-price {
  text-align: center;
  font-size: 22px;
  color: #f3123e;
  font-family: -win10-, -win10;
  font-weight: normal;
}

.buy-btn {
  width: 116px;
  height: 28px;
  border: 1px solid #9cc0d2;
  border-radius: 14px;
  margin: 16px auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.buy-btn .cart-box {
  width: 28px;
  height: 28px;
  border-right: 1px solid #9cc0d2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-btn .cart-box img {
  width: 19px;
  height: 19px;
}

.buy-btn .buy-text {
  font-size: 14px;
  color: #83b0c7;
  text-align: center;
  flex: 1;
}
</style>