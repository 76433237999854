<template>
  <div class="home center-wrap">
    <div class="home-content">
      <!-- 轮播图 -->
      <v-home-banner :bannerList="bannerList"></v-home-banner>

      <!-- 优惠活动 -->
      <v-home-activity :adList="adList"></v-home-activity>
      <!-- 推荐商品列表 -->
      <div class="home-product-list-box">
        <div v-for="(productGroup,index) in categoryProduct" :key="index">
          <v-home-product-list
            :productGroup="productGroup"
            v-if="productGroup.category_goods_arr && productGroup.category_goods_arr.length"
          ></v-home-product-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import ShopItem from '../components/shopItem.vue'
import HomeActivity from '../components/homeActivity.vue'
import HomeProductList from '../components/homeProductList.vue'
import homeBanner from '../components/homeBanner.vue'

export default {
  name: 'home',
  data() {
    return {
      empty: !1, //首页列表数据是否为空
      swiperOption: {
        initialSlide: 0,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      categoryProduct: [], // 分类商品列表
      bannerList: [], //轮播图数据
      adList: [], // 广告位数据
    }
  },
  created() {
    var self = this
    var local = localStorage.getItem('local')
    this.httpGetHomeProList(local) // 获取首页商品列表
    self.httpGetBannerList(local)
  },
  methods: {
    /**
     * 请求轮播图列表
     */
    httpGetBannerList(local) {
      var self = this
      this.common
        .httpPost(
          '/api/ajax/getBannerList',
          { local: local ? local : this.common.default_local },
          false
        )
        .then(function (ret) {
          if (ret.code == 1) {
            self.bannerList = ret.data.banner
            self.adList = ret.data.adsense
          }
        })
    },
    /**
     * 获取首页商品列表
     * @return {[type]} [description]
     */
    httpGetHomeProList(local) {
      var self = this
      self.common
        .httpPost(
          '/api/ajax/getCategory3AndGoods',
          { local: local ? local : this.common.default_local },
          true
        )
        .then((ret) => {
          if (ret.code == 1) {
            self.categoryProduct = ret.data
          }
        })
    },
    toAboutPage() {
      this.$router.push('/about')
    },
    /* 切换语言 */
    changeLnage(local) {
      this.$i18n.locale = this.$i18n.locale == 'zhCHS' ? 'zhCHT' : 'zhCHS'
    },
  },
  components: {
    swiper,
    swiperSlide,
    'v-shop-item': ShopItem,
    'v-home-activity': HomeActivity,
    'v-home-product-list': HomeProductList,
    'v-home-banner': homeBanner,
  },
}
</script>
<style scoped>
.home {
  width: 100%;
  background-color: #f7f7f7;
  min-height: 600px;
}

.home-content {
  width: 1366px;
  margin: auto;
}

.shadow {
  -webkit-box-shadow: #666 0px 0px 10px;
  -moz-box-shadow: #666 0px 0px 10px;
  box-shadow: #666 0px 0px 10px;
  background: #eeff99;
  width: 100px;
  height: 100px;
  margin: 200px auto;
  border-radius: 50%;
}

.play-photo {
  height: 582px;
  margin-bottom: 43px;
  position: relative;
}

.user-status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 88;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(225, 36, 27, 0.3);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
}

.play-photo .banner {
  width: 100%;
  height: 582px;
}

.type-block {
  padding-left: 82px;
  padding-right: 70px;
}

.title-box {
  padding-top: 26px;
  margin-bottom: 46px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-box div {
  height: 1px;
  width: 54.6px;
  background: #999999;
}

h1 {
  margin-left: 11px;
  margin-right: 11px;
  font-size: 20px;
  color: #999999;
  line-height: 20px;
  font-weight: 400;
  font-family: PingFangSC-Regular;
}

.wares-box {
  display: flex;
  flex-wrap: wrap;
}

.wares {
  height: 450px;
  width: 290px;
  margin-right: 13px;
}

.wares > img {
  height: 290px;
  width: 290px;
}

.wares > p {
  font-size: 20px;
  color: #e1241b;
  line-height: 21px;
  margin-top: 9px;
  text-align: center;
}

.wares p span {
  font-size: 18px;
  color: #999999;
  line-height: 21px;
  margin-left: 6px;
  text-decoration: line-through;
}

h2 {
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  margin-top: 25px;
  text-align: center;
  font-weight: 500;
}

.wares div {
  height: 28px;
  width: 116px;
  border: 0.6px solid rgba(156, 192, 210, 0.7);
  margin: auto;
  border-radius: 14px;
  display: flex;
  align-items: center;
  margin-top: 17px;
}

.wares div img {
  height: 19px;
  width: 19px;
  margin-left: 12px;
  margin-right: 4.5px;
}

.wares div p {
  padding-left: 9.5px;
  line-height: 28px;
  color: #9cc0d2;
  font-size: 14px;
  border-left: 0.6px solid rgba(156, 192, 210, 0.7);
}

.more {
  height: 40px;
  width: 130px;
  background: #297fab;
  border-radius: 3px;
  margin: 15px auto 80px;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

.home-product-list-box {
  padding-top: 10px;
  padding-bottom: 20px;
}
</style>
<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 8px;
}

.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  background: rgba(255, 255, 255, 1) !important;
  box-shadow: #fff 0px 0px 10px;
  border-radius: 50%;
}
</style>