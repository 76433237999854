<template>
  <div class="homeActivity" v-if="adList.length">
    <div class="activity-item click" v-for="item in adList" :key="item.id">
      <img :src="item.image_url" @click.stop="handleBannerClick(item)" />
    </div>
    <div class="activity-item" v-for="i in 3 - adList.length" :key="i"></div>
  </div>
</template>
<script>
export default {
  name: "AddAddress",
  data() {
    return {};
  },
  props: {
    adList: {
      type: Array,
      value: []
    }
  },
  created() {},
  methods: {
    handleBannerClick(item) {
      if (item.type == 1 && item.url) {
        // 打开外链
        window.open(item.url);
      } else if (item.type == 2 && item.goods_id) {
        // 打开商品详情页
        this.toProductDetailPage(item.goods_id);
      } else if (item.type == 3 && item.category_id) {
        // 打开商品详情页
        this.toProListPage(item.category_id);
      } else if (item.type == 4 && item.goods_discount_id) {
        var id = item.goods_discount_id;
        this.toProductActivityPage(id);
      }
    },
    /*打开活动商品页面*/
    toProductActivityPage: function(id) {
      let routeData = this.$router.resolve({
        path: "/activity",
        query: {
          activity_id: id
        }
      });
      window.open(routeData.href, "_blank");
    },
    /*商品详情页面*/
    toProductDetailPage(product_id) {
      let routeData = this.$router.resolve({
        path: "/product",
        query: {
          product_id: product_id
        }
      });
      window.open(routeData.href, "_blank");
    },
    /** 商品列表页面 (分类) */
    toProListPage(category_id) {
      let routeData = this.$router.resolve({
        path: "/list",
        query: {
          category_id: category_id
        }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped>
.homeActivity {
  margin: 30px 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homeActivity .activity-item {
  height: 260px;
  width: 380px;
  display: flex;
  align-items: center;
}

.homeActivity .activity-item img {
  height: 260px;
  width: 380px;
}
</style>