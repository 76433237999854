<template>
    <div class="shopItem">
        <div class="wares" v-if="product">
            <div class="shop-cover">
                <!-- <img src="../assets/icon/photo.png" class="click" @click.stop="toProductDetailPage"> -->
                <img :src="product.images_arr[0]" class="click" @click.stop="toProductDetailPage">
                <p v-if="product.goods_discount">{{product.goods_discount}}</p>
            </div>
            <h2 class="click product-name" @click.stop="toProductDetailPage" v-text="product.name"></h2>
            <!-- 登录 并且有 vip价格 -->
            <p v-if="userinfo && product.goods_specs_show && product.goods_specs_show.price_vip">
                {{common.showPrice(product.goods_specs_show.price_vip,product.goods_specs_show.price_type)}}
                <span v-if="product.goods_specs_show.original_price">
                    {{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}
                </span>
            </p>
            <!-- 登录 会员价格 -->
            <p v-else-if="userinfo && product.goods_specs_show && !product.goods_specs_show.price_vip">
                {{common.showPrice(product.goods_specs_show.price,product.goods_specs_show.price_type,false,true)}}
                <span v-if="product.goods_specs_show.original_price">
                    {{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}
                </span>
            </p>
            <p v-else-if="!userinfo && product.goods_specs_show">
                {{common.showPrice(product.goods_specs_show.price,product.goods_specs_show.price_type,false,true)}}
                <span v-if="product.goods_specs_show.original_price">
                    {{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}
                </span>
            </p>
            <p v-else>¥暂无价格</p>
            <div class="click">
                <img src="../assets/icon/shopping.png" @click.stop="fnChooseSpec('cart')">
                <!-- <img src="../assets/icon/shopping.png"> -->
                <p v-text="$t('home.bug')" @click.stop="fnChooseSpec('buy')">立即购买</p>
            </div>
        </div>
        <v-choose-spec v-if="showChooseSpec" @fnCancelChoose="showChooseSpec=false" :product="product" :showChooseSpecType="showChooseSpecType"></v-choose-spec>
    </div>
</template>
<script>
import chooseSpec from '../components/chooseSpec.vue'

export default {
    name: 'ShopItem',
    data() {
        return {
            showChooseSpecType: null,
            showChooseSpec: false,
            userinfo: this.common.getUserInfo()
        }
    },
    props: {
        product: {
            type: Object
        }
    },
    methods: {
        fnChooseSpec: function(type) {
            this.showChooseSpecType = type;
            this.showChooseSpec = true;
        },

        /**
         * 跳转到商品详情页面
         * @param  {[type]} product_id [商品id]
         * @return {[type]}            [description]
         */
        toProductDetailPage(product_id) {
            let routeData = this.$router.resolve({
                path: "/product",
                query: {
                    product_id: this.product.id
                }
            });
            window.open(routeData.href, '_blank');
        },
        /* 检查产品线是否可以购买 */
        checkGoodsStatus: function(ids, fn) {
            var self = this;
            self.common.httpPost("/api/order/checkGoodsStatus", {
                ids: ids
            }).then((ret) => {
                if (ret.code == 1) {
                    fn && fn();
                } else self.common.showMessage(ret.msg, 'error');
            })
        },
        /*立即购买*/
        toOrderConfirmPage() {
            var self = this;
            self.checkGoodsStatus(self.product.goods_specs_show.id, function() {
                self.$router.push({
                    path: "/orderConfirm",
                    query: {
                        params: JSON.stringify([{ specs_id: self.product.goods_specs_show.id, number: 1 }]),
                        cart_id: ''
                    }
                })
            })
        },
        /**
         * 加入购物车
         */
        joinCart() {
            var self = this;
            self.checkGoodsStatus(self.product.goods_specs_show.id, function() {
                self.common.httpPost("/api/cart/addCart", {
                    goods_id: self.product.id,
                    specs_id: self.product.goods_specs_show.id,
                    number: 1
                }).then((ret) => {
                    if (ret.code == 1) {
                        self.common.showMessage('加入购物车成功', 'success');
                        eventBus.$emit('RefreshCartNumber');
                    }
                })
            })
        },
    },
    components: {
        "v-choose-spec": chooseSpec
    }
}
</script>
<style scoped>
.wares-box {
    display: flex;
    flex-wrap: wrap;
}

.wares {
    height: 450px;
    width: 290px;
    margin-right: 13px;
}

.discount-box {
    position: absolute;
}

.wares>p {
    font-size: 20px;
    color: #E1241B;
    line-height: 21px;
    margin-top: 6px;
    text-align: center;
    /* font-weight: 600; */
    font-family: "SimHei", "PingFangSC-Medium", sans-serif;
}

.wares p span {
    font-size: 18px;
    color: #999999;
    line-height: 21px;
    margin-left: 6px;
    text-decoration: line-through;
}

h2 {
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    margin-top: 25px;
    text-align: center;
    font-weight: 500;
}

.wares div {
    height: 28px;
    width: 116px;
    border: 0.6px solid rgba(156, 192, 210, 0.7);
    margin: auto;
    border-radius: 14px;
    display: flex;
    align-items: center;
    margin-top: 17px;
}

.wares .shop-cover {
    height: 290px;
    width: 290px;
    position: relative;
    border: none;
}

.wares .shop-cover p {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(54, 135, 176, 0.6);
    height: 35px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wares div img {
    height: 19px;
    width: 19px;
    margin-left: 12px;
    margin-right: 4.5px;
}

.wares .shop-cover>img {
    height: 290px;
    width: 290px;
    margin-left: 0px;
    -o-object-fit: contain;
    object-fit: contain;
}

.wares div p {
    padding-left: 9.5px;
    line-height: 28px;
    color: #9CC0D2;
    font-size: 14px;
    border-left: 0.6px solid rgba(156, 192, 210, 0.7);
}

.product-name {
    overflow: hidden;
    height: 50px;
    line-height: 25px;
    margin-top: 15px;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-wrap: break-word;
    /* word-break: break-all; */
}
</style>