<!-- 单个商品显示 -->
<template>
  <div class="homeProductItem">
    <div class="priduct-item" @mouseleave="showDiscountList=false">
      <div class="image-box" @click.stop="toProductDetailPage(product.id)">
        <img :src="product.images_arr[0] + '?x-oss-process=image/auto-orient,1/interlace,1/resize,m_lfit,w_360/quality,q_35'" />
        <!-- <div
          class="dicount-name"
          v-if="product.goods_discount_name"
        >{{product.goods_discount_name | showDiscountName}}</div>-->

        <div
          class="dicount-content"
          v-if="product.goods_discount && local == 2"
          @mouseover="showDiscountList=true"
        >{{product.goods_discount.title | traditional}}</div>

        <div
          class="dicount-content"
          v-if="product.goods_discount && local == 1"
          @mouseover="showDiscountList=true"
        >{{product.goods_discount.title}}</div>

        <div
          class="discount-list"
          v-if="product.goods_discount && product.goods_discount.list"
          v-show="showDiscountList"
          @mouseleave="showDiscountList=false"
        >
          <p v-for="(item,index) in product.goods_discount.list" :key="index">
            <span>{{item.min + '-' + item.max + '本:'}}</span>
            <span>{{item.rebate+'折' | showRebate}}</span>
          </p>
        </div>
      </div>
      <div class="product-name" @click.stop="toProductDetailPage(product.id)">{{product.name}}</div>

      <!-- 登录 并且有 vip价格 -->
      <!-- <div
        class="product-price"
        v-if="userinfo && product.goods_specs_show && product.goods_specs_show.price_vip"
      >
        {{common.showPrice(product.goods_specs_show.price_vip,product.goods_specs_show.price_type)}}
        <br />
        <span>{{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}</span>
      </div>-->

      <!-- 登录 会员价格 -->
      <!-- <div
        class="product-price"
        v-else-if="userinfo && product.goods_specs_show && !product.goods_specs_show.price_vip"
      >
        {{common.showPrice(product.goods_specs_show.price,product.goods_specs_show.price_type,false,true)}}
        <br />
        <span>{{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}</span>
      </div>-->

      <!-- 普通价格 -->
      <div class="product-price">
        {{common.showDiscountPrice(product,userinfo)}}
        <br />
        <span>{{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}</span>
      </div>

      <div class="buy-btn">
        <div class="cart-box">
          <img src="../assets/icon/shopping-car.png" @click.stop="fnChooseSpec('cart',product)" />
        </div>
        <div class="buy-text" @click.stop="fnChooseSpec('buy',product)">{{$t('home.bug')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "homeProductItem",
  data() {
    return {
      showDiscountList: false,
      userinfo: this.common.getUserInfo()
    };
  },
  computed: {
    local: function() {
      if (!this.userinfo) return this.common.default_local;
      else return this.userinfo.local;
    }
  },
  props: {
    product: {
      type: Object
    }
  },
  created: function() {
    // console.log(this.product);
  },
  methods: {
    fnChooseSpec: function(type, product) {
      this.$emit("chooseSpecShow", {
        type: type,
        product: product
      });
    },
    /**
     * 跳转到商品详情页面
     * @param  {[type]} product_id [商品id]
     * @return {[type]}            [description]
     */
    toProductDetailPage(product_id) {
      let routeData = this.$router.resolve({
        path: "/product",
        query: {
          product_id: product_id
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  components: {},
  filters: {
    showRebate: function(value) {
      return value.replace(".", "");
    }
  }
};
</script>
<style scoped>
.priduct-item {
  width: 170px;
  height: 100%;
  padding: 20px 0;
  cursor: pointer;
}

.priduct-item .image-box {
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.priduct-item .image-box .dicount-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 22px;
  line-height: 22px;
  background-color: #ff2d56;
  color: white;
  font-size: 14px;
  text-align: center;
}

.priduct-item .image-box .discount-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ff2d56;
  background-color: white;
  padding: 5px 0;
}

.priduct-item .image-box .discount-list p {
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.priduct-item .image-box .discount-list p span {
  flex: 1;
}

.priduct-item .image-box .dicount-name {
  position: absolute;
  top: 0;
  right: 0;
  height: 26px;
  line-height: 26px;
  color: white;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 5px;
  background-image: url("../assets/icon/discount-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.priduct-item .image-box img {
  width: 170px;
  height: 170px;
  -o-object-fit: contain;
  object-fit: contain;
}

.priduct-item .product-name {
  color: #333;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin: 11px 15px;
  text-align: center;
  height: 63px;
}

.priduct-item .product-price {
  text-align: center;
  font-size: 22px;
  color: #f3123e;
  font-family: SimHei, PingFangSC-Medium, PingFang SC, sans-serif;
  /* font-family: -win10-, -win10; */
  font-weight: normal;
}

.priduct-item .product-price span {
  color: rgba(153, 153, 153, 1);
  font-family: SimHei, PingFangSC-Medium, PingFang SC, sans-serif;
  /* font-family: -win10-, -win10; */
  font-weight: normal;
  font-size: 16px;
  text-decoration: line-through;
}

.buy-btn {
  width: 116px;
  height: 28px;
  border: 1px solid #9cc0d2;
  border-radius: 14px;
  margin: 16px auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.buy-btn .cart-box {
  width: 28px;
  height: 28px;
  border-right: 1px solid #9cc0d2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-btn .cart-box img {
  width: 19px;
  height: 19px;
}

.buy-btn .buy-text {
  font-size: 14px;
  color: #83b0c7;
  text-align: center;
  flex: 1;
}
</style>