<template>
  <div class="homeBanner">
    <!-- 轮播图 -->
    <div class="play-photo" v-if="bannerList && bannerList.length">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item in bannerList" :key="item.id">
          <img :src="item.image_url" class="banner" @click.stop="handleBannerClick(item)" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "homeBanner",
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        loop: this.isLoop,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  props: {
    bannerList: {
      type: Array,
      value: []
    }
  },
  computed: {
    isLoop: function() {
      if (!this.bannerList) return false;
      if (this.bannerList.length <= 1) return false;
      return true;
    }
  },
  created: function() {},
  methods: {
    handleBannerClick(item) {
      if (item.type == 1 && item.url) {
        // 打开外链
        window.open(item.url);
      } else if (item.type == 2 && item.goods_id) {
        // 打开商品详情页
        this.toProductDetailPage(item.goods_id);
      } else if (item.type == 3 && item.category_id) {
        // 打开商品详情页
        this.toProListPage(item.category_id);
      } else if (item.type == 4 && item.goods_discount_id) {
        var id = item.goods_discount_id;
        this.toProductActivityPage(id);
      }
    },
    /*打开活动商品页面*/
    toProductActivityPage: function(id) {
      let routeData = this.$router.resolve({
        path: "/activity",
        query: {
          activity_id: id
        }
      });
      window.open(routeData.href, "_blank");
    },
    toProductDetailPage(product_id) {
      this.$router.push({
        path: "/product",
        query: {
          product_id: product_id
        }
      });
    },
    /** 商品列表页面 (分类) */
    toProListPage(category_id) {
      let routeData = this.$router.resolve({
        path: "/list",
        query: {
          category_id: category_id
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  components: {
    swiper,
    swiperSlide
  }
};
</script>
<style scoped>
.play-photo {
  height: 582px;
  overflow: hidden;
  margin-bottom: 43px;
  position: relative;
}

.play-photo .banner {
  width: 100%;
  height: 582px;
}
</style>
<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 8px;
}

.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  background: rgba(255, 255, 255, 1) !important;
  box-shadow: #fff 0px 0px 10px;
  border-radius: 50%;
}
</style>